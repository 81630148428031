/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import { useSubmitUnitPriceMutation } from '../../../components/api/BuyerApi'
import Spinner from '../../../components/spinner'
import { NOOP } from '../../../components/utils'
import InfoIconSvg from '../../../images/info-sign.svg'

/**
 *
 * !!! Page !!!
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const Page = (props) => {
  // props
  const { openModal = true, setOpenModal = NOOP } = props
  const { listingId = '346', listingLotId = '1260', areUnitPricesIncomplete = true } = props

  // query
  const submitUnitPriceMutation = useSubmitUnitPriceMutation()

  // functions
  const onHide = () => {
    setOpenModal(false)
    submitUnitPriceMutation.reset()
  }
  const internalOnSave = () => {
    submitBenchmarkFile()
  }

  /**
   * Upload the product manifest file.
   */
  const submitBenchmarkFile = () => {
    console.debug('Sending unitprices to backend...')
    submitUnitPriceMutation.mutate({
      listingId,
      listingLotId
    })
  }

  return <>

        <Modal show={openModal} onHide={onHide}>
            <Modal.Body>
                <h5 className="m-0 mb-3"><img src={InfoIconSvg} className="mr-3" />Clear IMEI?</h5>

                {/* buttons */}
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={onHide} variant="outline-primary">Cancel</Button>
                    <Button type="button" onClick={internalOnSave} variant="primary" disabled={areUnitPricesIncomplete}>
                        {submitUnitPriceMutation.isLoading ? <Spinner /> : <span>Clear</span>}
                    </Button>
                </div>

                {/* { process.env.NODE_ENV !== 'production' && <Button className="mt-3" onClick={() => clearFile()}>Clear File</Button>} */}

                { submitUnitPriceMutation.isSuccess && <Alert variant="success" className="mb-0 mt-3 text-center">Clear successful!</Alert> }
                { submitUnitPriceMutation.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error clearing IMEI. ' + submitUnitPriceMutation.error.message}</Alert> }

            </Modal.Body>
        </Modal>

        {/* {process.env.NODE_ENV !== 'production' && <pre>listingLotSkus={JSON.stringify(listingLotSkus, null, 2)}</pre>} */}
      </>
}

export default Page
